import { useRef, useState, useEffect } from "react";
import { teamMembers } from "../constants/TeamMembers";
import { useNavigate } from "react-router-dom";

const TeamMembers = () => {
  const navigate = useNavigate();
  const headerRef = useRef<HTMLDivElement>(null);
  const teamRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState(1);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // For scaling the header
      if (headerRef.current) {
        const elementTop = headerRef.current.getBoundingClientRect().top;
        const triggerPoint = window.innerHeight / 3;
        const newScale = Math.min(1.1, 1 + (triggerPoint - elementTop) / 800);
        setScale(newScale);
      }

      // For changing the color based on scroll position
      const scrollPosition = window.scrollY;
      setIsScrolled(scrollPosition > 110); // Adjust this threshold as needed
    };

    // Initial check
    handleScroll();

    // Add scroll listener
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <section>
      <div
        ref={teamRef}
        className={`transition-colors duration-500 overflow-x-hidden
          sm:bg-gray
          ${isScrolled ? "lg:bg-gray" : "lg:bg-darkGreen"} 
        `}
      >
        <div
          ref={headerRef}
          style={{
            transform: `scale(${scale})`,
            transition: "transform 0.2s ease-out",
          }}
          className={`px-8 pt-24 py-0 md:px-32 md:py-32 font-productSans font-bold 
            sm:text-darkGreen ${isScrolled ? "lg:text-white" : "lg:text-gray"} 
            text-[28px] sm:text-[28px] md:text-[32px] lg:text-[48px] xl:text-[52px] 
            2xl:text-[56px] lg:leading-[55px] xl:leading-[70px] 2xl:leading-[70px] 
            text-center md:tracking-wide`}
        >
          Meet our Team of{" "}
          <span className="bg-gray text-darkGreen">
            Creators, Designers and Problem Solvers
          </span>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-4 justify-items-center my-8 mx-8 lg:mx-20 md:gap-4">
          {teamMembers.map((member, index) => (
            <div
              key={index}
              className={`flex flex-col items-center md:mb-8 p-8 lg:p-8 
                ${isScrolled ? "shadow-xl" : ""} 
                group hover:shadow-md rounded-xl animate-fadeBottom`}
            >
              <div className="bg-gray rounded-[50%] w-[120px] h-[120px] md:w-[120px] md:h-[120px] lg:w-[150px] lg:h-[150px] xl:w-[200px] xl:h-[200px] group-hover:bg-white overflow-hidden">
                <img
                  className="w-full h-full object-cover"
                  src={member.imgSrc}
                  alt={member.name}
                />
              </div>
              <div className="mt-4 mb-2 font-productSans font-bold text-center text-darkGreen sm:text-[16px] md:text-[16px] lg:text-[18px] xl:text-[20px] 2xl:text-[22px] tracking-wide">
                {member.name}
              </div>
              <div className="mb-4 font-archivo font-normal text-center text-darkGreen text-[12px] md:text-[12px] lg:text-[14px] tracking-wide">
                {member.description}
              </div>
              <button
                onClick={() => navigate(member.link)}
                className="px-4 py-2 font-dmSans font-normal text-center text-darkGreen text-[12px] md:text-[12px] lg:text-[14px] group-hover:text-black rounded-md border-[1px] cursor-pointer"
              >
                Read more
              </button>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TeamMembers;
