import { useState } from 'react'

import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

import url from '../config';
import { Modal } from '../component/ui/Modal';
import { Button, InputText } from '../component/ui'

const ContactUS = () => {
    let navigate = useNavigate();
    const [fullName, setFullName] = useState("");
    const [comment, setComment] = useState("");
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [showModel, setShowModel] = useState(false);

    const onSubmit = () => {
        setLoading(true)
        const body = {
            fullName: fullName,
            comment: comment,
            email: email.toLowerCase(),
        };
        axios
            .post(`${url}/contact`, body)
            .then((res) => {
                setLoading(false);
                setShowModel(true);
                toast.success("Message Sent Successfully!!");
            })
            .catch((err) => {
                console.log(err?.response?.data?.errors[0]?.detail, "err");
                setLoading(false)
                toast.error(err?.response?.data?.errors[0]?.detail);
            });
    }

    return (
        <section className="lg:h-screen h-screen items-center md:pt-24 lg:pt-[88px] pt-[120px] px-3">
            <div className="max-w-3xl mx-auto flex flex-col justify-center items-center text-center space-y-2 mb-5">
                <h3 className="font-inter lg:text-2xl text-base font-bold uppercase">
                    we are excited you want to help us further our mission!
                </h3>
                <p className="font-dmSans font-normal lg:text-xl text-xs max-w-lg">
                    Leave us a note and join our discord page, we will connect with you shortly!
                </p>
            </div>
            <div className="max-w-4xl mx-auto">
                <div className="grid lg:grid-cols-2 md:grid-cols-2 gap-3">
                    <InputText
                        title={"Full Name"}
                        placeholder={""}
                        className="w-full"
                        required
                        onChange={(e) => setFullName(e)}
                    />
                    <InputText
                        title={"Email address"}
                        placeholder={""}
                        className="w-full"
                        required
                        onChange={(e) => setEmail(e)}
                    />
                </div>
                <div className="col-span-2">
                    <label className="mb-1 capitalize font-dmSans text-base font-bold text-textColor">Comment</label>
                    <textarea
                        className="w-full border-[0.5px] bg-white text-black focus:outline-none focus:border-[0.5px] border-textColor focus:border-gray p-2 font-semibold rounded-md"
                        rows={4}
                        onChange={(e) => setComment(e.target.value)}
                    />
                </div>
            </div>
            <div className="flex justify-center my-5 ">
                <Button
                    outlined
                    loading={loading}
                    onClick={() => onSubmit()}
                    className="font-archivo font-thin"
                >
                    Submit
                </Button>
            </div>
            <Modal title="" show={showModel} onClose={() => navigate("/")}>
                <div className="px-10 mb-3 max-w-xl ">
                    <h3 className="font-inter font-bold text-center m-auto text-xl mb-5 capitalize">
                        thank you, we have received your response
                    </h3>
                    <div className="font-dmSans font-normal text-center m-auto text-xl">Join our discord group and get more updates!</div>
                    <div className="flex items-center justify-center space-x-5 py-4">
                        <img src="/assets/images/icons/discord.png" alt="discord" />
                        <div className="font-dmSans font-bold text-base text-black">
                            Discord
                        </div>
                    </div>
                    <div className="mt-3 flex flex-col justify-center items-center">
                        <button
                            className="bg-acapulco text-base font-bold font-dmSans text-white rounded-full w-40 h-9"
                            onClick={() => window.open("https:discord.gg/VcjDkXAwAt")}
                        >
                            Join
                        </button>
                    </div>
                </div>
            </Modal>
        </section>
    )
}

export default ContactUS