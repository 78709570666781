import React from "react";
import { SiteLogo } from "../../Icons";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { FaLinkedin } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";

const Footer = () => {
  let navigate = useNavigate();

  return (
    <footer className="bg-darkGreen flex flex-row justify-between py-2">
      {/* <div className="flex flex-col justify-between gap-16 mx-24 my-8">
        <div>
          <img
            src="/assets/images/logo/logo.png"
            width={70}
            height={70}
            onClick={() => navigate("/")}
          />
        </div>
        <div>
          <div>
            <p className="text-white font-bold font-dmSans">Need Help?</p>
            <p className="text-white font-dmSans">
              Reach us at:{" "}
              <span className="text-blue underline">info@fownd.care</span>
            </p>
          </div>

          <div className="flex flex-row gap-2 my-4">
            <FaLinkedin color="white" size={25} />
            <FaFacebook color="white" size={25} />
          </div>
        </div>
      </div>

      <div className="flex flex-row gap-32 my-12 mx-48">
        <div className="flex flex-col text-white font-dmSans text-[17px]">
          <h3 className="font-bold">Fownd</h3>
          <div className="my-6 flex flex-col gap-2">
            <p>Notation AI</p>
            <p>About</p>
            <p>Contact</p>
            <p>Founder's Note</p>
          </div>
        </div>

        <div className="flex flex-col text-white font-dmSans text-[17px]">
          <h3 className="font-bold">Support</h3>
          <div className="my-6 flex flex-col gap-2">
            <p>Privacy Policy</p>
            <p>Cookies</p>
            <p>Terms & Conditions</p>
          </div>
        </div>
      </div> */}

      <div className="mx-7 md:ml-14">
        <SiteLogo
          onClick={() => navigate('/')}
          className="text-white cursor-pointer w-4 sm:w-12"
        />
      </div>
      <div className="mx-4">
        <div className="grid grid-cols-4 md:grid-cols-4 h-16 text-white justify-items-start content-center">
          <div className="font-dmSans">
            <HashLink
              to="/#section-1"
              className="block text-xs sm:text-[13px] md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px]"          
            >
              Home
            </HashLink>
          </div>
          <div className="font-dmSans">
            <Link
              to="/about"
              className="block text-xs sm:text-[13px] md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px]"
            >
              About
            </Link>
          </div>
          <div className="font-dmSans">
            <Link
              to="/invest"
              className="block text-xs sm:text-[13px] md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px]"
            >
              Contact
            </Link>
          </div>
          <div className="font-dmSans">
            <Link
              to="/founder-notes"
              className="block text-xs sm:text-[13px] md:text-[14px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px]"
            >
              Founder’s note
            </Link>
          </div>
        </div> 
      </div>
    </footer>
  );
};

export default Footer;
