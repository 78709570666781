/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, Suspense, lazy } from "react";
import { FaPenFancy } from "react-icons/fa";
import feature1 from "../../component/Icons/feature-1.json";
import feature2 from "../../component/Icons/feature-2.json";
import feature3 from "../../component/Icons/feature-3.json";
import { FaArrowRight } from "react-icons/fa";

import Lottie from "lottie-react";
import { Link } from "react-router-dom";
import ReactPlayer from "react-player";

const Section = lazy(() => import("./Section"));

const Intro = () => {
  // Define refs for each section
  const sectionOneRef = useRef<HTMLDivElement | null>(null);
  const sectionTwoRef = useRef<HTMLDivElement | null>(null);
  const sectionThreeRef = useRef<HTMLDivElement | null>(null);
  const sectionFourRef = useRef<HTMLDivElement | null>(null);
  const sectionFiveRef = useRef<HTMLDivElement | null>(null);

  const [currentSection, setCurrentSection] = useState<number>(0);

  const [sectionOneAnimation, setSectionOneAnimation] = useState(null);
  const [sectionTwoAnimation, setSectionTwoAnimation] = useState(null);
  const [sectionThreeAnimation, setSectionThreeAnimation] = useState(null);
  const [sectionFourAnimation, setSectionFourAnimation] = useState(null);
  const [sectionFiveAnimation, setSectionFiveAnimation] = useState(null);

  const sections = [
    {
      title:
        "AI-Assisted SOAP Notes for Physical Therapists | Streamline Clinical Documentation",
      description:
        "Revolutionize your physical therapy practice with AI-assisted SOAP note generation. Modernize, automate, and enhance clinical documentation, saving time, reducing physical therapist burnout, and improving accuracy. Discover our AI-powered solutions today.",
      keywords:
        "AI SOAP notes, physical therapy documentation, AI clinical notes, automated SOAP notes, AI in physical therapy, AI services for EMR and HER systems.",
      altText:
        "AI-powered interface for generating near autonomous SOAP clinical notes in physical therapy.",
      url: "fownd.care/ai-soap-notes-physical-therapy",
    },
    {
      title:
        "AI-assisted capture of language, speakers, and clinical context in real-time",
      description:
        "FOWND’s custom multi-modal model implementation captures and transcribes patient and treatment context to create a highly accurate patient-rich subjective assessment.",
      keywords:
        "Transcription, Clinical Context in natural language processing",
      altText:
        "AI and Natural language processing powered interface capturing subjective assessments in physical therapy.",
      url: "fownd.care/ai-assisted-subjective-assessment-physical-therapy",
    },
    {
      title:
        "Computer vision using simple web camera to capture Objective details of the patient",
      description:
        "FOWND’s novel approach to capturing P.I.I free clinical data to accurately assist in assessing ROM, posture, and functional movements. Standardizing measurements and helping further train AI models for consistent real-world clinical results.",
      keywords:
        "Vision-based Objective Assessments, Pose Estimation, Posture assessment, functional movement analysis, Edge Inferencing.",
      altText:
        "Computer Vision based inference of patients helping deliver accurate and consistent care in physical therapy.",
      url: "fownd.care/fownd-computer-vision-objective-assessment-physical-therapy",
    },
    {
      title:
        "FOWND predictive and analytical AI models help with note compliance and billing readiness",
      description:
        "FOWNDs used unique data classification and contextual data mining to assist physical therapists in capturing the right therapy and medical codes for diagnosis and also provides them invaluable data to build the best care plans and home exercise programs for the patient. No more second guessing, endless clicking to build plans and selecting the right billable codes.",
      keywords:
        "Data Analysis, SOAP note Accuracy, Compliance, reduced burnout, reduced administrative tasks",
      altText:
        "Autonomous human-in-the-loop AI, note that helps reduce physical therapy workloads, creating the a note before the session ends for sign-off.",
      url: "fownd.care/fownd-data-driven-predictive-physical-therapy-soap-note",
    },
    {
      title:
        "FOWND develops a real-time autonomous note-keeping clinical skill and patient care at the forefront from intake to discharge.",
      description:
        "FOWND approach doesn’t just end with transcription. FOWND produces a comprehensive SOAP note backed by data, reducing the need for lookups and second-guessing a care plan while also helping improve intake and tracking through the patient care lifecycle. Clinical notes get better with each session with learnings and data.",
      keywords:
        "Comprehensive, Compliant, reduced burnout, reduced administrative tasks, remote therapy monitoring, improved physical rehabilitation.",
      altText:
        "AI that supports every patient visit, assists in monitoring progress, and enables remote monitoring applications for physical therapy with minimal guesswork.",
      url: "fownd.care/fownd-ai-comprehensive-complaint-soap-note",
    },
  ];

  // Function to update metadata
  const updateMetadata = (index: number) => {
    const slide = sections[index];
    document.title = slide.title;
    const metaDescription = document.querySelector(
      'meta[name="description"]'
    ) as HTMLMetaElement;
    const metaKeywords = document.querySelector(
      'meta[name="keywords"]'
    ) as HTMLMetaElement;
    const metaImage = document.querySelector(
      'meta[name="image"]'
    ) as HTMLMetaElement;
    const linkCanonical = document.querySelector(
      'link[rel="canonical"]'
    ) as HTMLLinkElement;

    if (metaDescription) metaDescription.content = slide.description;
    if (metaKeywords) metaKeywords.content = slide.keywords;
    if (metaImage) metaImage.content = slide.altText;
    if (linkCanonical) linkCanonical.href = slide.url;
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.5,
    };

    const sectionOne = sectionOneRef.current;
    const sectionTwo = sectionTwoRef.current;
    const sectionThree = sectionThreeRef.current;
    const sectionFour = sectionFourRef.current;
    const sectionFive = sectionFiveRef.current;

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          if (entry.target === sectionOne) {
            setCurrentSection(0);
            if (!sectionOneAnimation) fetchAnimation(0);
          }
          if (entry.target === sectionTwo) {
            setCurrentSection(1);
            if (!sectionTwoAnimation) fetchAnimation(1);
          }
          if (entry.target === sectionThree) {
            setCurrentSection(2);
            if (!sectionThreeAnimation) fetchAnimation(2);
          }
          if (entry.target === sectionFour) {
            setCurrentSection(3);
            if (!sectionFourAnimation) fetchAnimation(3);
          }
          if (entry.target === sectionFive) {
            setCurrentSection(4);
            if (!sectionFiveAnimation) fetchAnimation(4);
          }
        }
      });
    }, options);

    if (sectionOne) observer.observe(sectionOne);
    if (sectionTwo) observer.observe(sectionTwo);
    if (sectionThree) observer.observe(sectionThree);
    if (sectionFour) observer.observe(sectionFour);
    if (sectionFive) observer.observe(sectionFive);

    return () => {
      if (sectionOne) observer.unobserve(sectionOne);
      if (sectionTwo) observer.unobserve(sectionTwo);
      if (sectionThree) observer.unobserve(sectionThree);
      if (sectionFour) observer.unobserve(sectionFour);
      if (sectionFive) observer.unobserve(sectionFive);
    };
  }, [
    sectionOneAnimation,
    sectionTwoAnimation,
    sectionThreeAnimation,
    sectionFourAnimation,
    sectionFiveAnimation,
  ]);

  // Function to fetch animation JSON data based on the section number
  const fetchAnimation = async (sectionIndex: number) => {
    let url = "";
    switch (sectionIndex) {
      case 0:
        url = "https://d2i4rspz3xgpwy.cloudfront.net/Slide+1.json";
        break;
      case 1:
        url = "https://d2i4rspz3xgpwy.cloudfront.net/Slide+2.json";
        break;
      case 2:
        url = "https://d2i4rspz3xgpwy.cloudfront.net/Slide+3.json";
        break;
      case 3:
        url = "https://d2i4rspz3xgpwy.cloudfront.net/Slide+4.json";
        break;
      case 4:
        url = "https://d2i4rspz3xgpwy.cloudfront.net/Slide+5.json";
        break;
      default:
        return;
    }

    try {
      const response = await fetch(url);
      const jsonData = await response.json();

      switch (sectionIndex) {
        case 0:
          setSectionOneAnimation(jsonData);
          break;
        case 1:
          setSectionTwoAnimation(jsonData);
          break;
        case 2:
          setSectionThreeAnimation(jsonData);
          break;
        case 3:
          setSectionFourAnimation(jsonData);
          break;
        case 4:
          setSectionFiveAnimation(jsonData);
          break;
      }
    } catch (error) {
      console.error("Error loading animation:", error);
    }
  };

  useEffect(() => {
    updateMetadata(currentSection);
  }, [currentSection]);

  return (
    <>
      {" "}
      {/* Section One */}
      <div ref={sectionOneRef} id="section-1" className="section-1 relative">
        <div className="flex flex-col bg-darkGreen justify-center lg:justify-normal items-center lg:items-normal h-screen lg:px-28 lg:py-3">
          <div className="flex flex-col md:flex-row lg:mt-48">
            <div className="flex flex-col px-4 pt-8 md:pt-0 md:my-4 md:justify-center items-center md:items-start md:flex-1">
              <div className="font-productSans font-bold text-gray text-[36px] sm:text-[36px] md:text-[36px] lg:text-[48px] xl:text-[58px] 2xl:text-[60px] lg:leading-[55px] xl:leading-[70px] 2xl:leading-[70px] max-w-[500px] text-center md:text-left md:tracking-wide">
                Fownding Modern
                <span className="block font-productSans font-bold text-blue text-[36px] sm:text-[36px] md:text-[36px] lg:text-[48px] xl:text-[58px] 2xl:text-[60px] lg:leading-[55px] xl:leading-[70px] 2xl:leading-[70px] md:tracking-wide">
                  Physical Therapy Documentation
                </span>
              </div>
              <div className="font-productSans font-normal text-beige text-[18px] sm:text-[18px] md:text-[20px] lg:text-[24px] xl:text-[26px] 2xl:text-[26px] max-w-[500px] text-center md:text-left mt-2 md:mt-4">
                Intuitive, AI-assisted clinical SOAP notes. Let technology take
                care of your documentation, and you take care of your patients!
              </div>
            </div>
            <div
              id="animation-1"
              className="px-4 md:my-auto w-full flex items-center justify-center flex-1 h-auto max-h-[360px]"
            >
              <Suspense
                fallback={
                  <img src="./assets/images/fallback/Slide1_fallback.png" />
                }
              >
                {sectionOneAnimation ? (
                  <Section animationData={sectionOneAnimation} />
                ) : (
                  <img src="./assets/images/fallback/Slide1_fallback.png" />
                )}
              </Suspense>
            </div>
          </div>
              <div className="absolute bottom-5 right-5">
                <img
                  height={70}
                  width={180}
                  src="./assets/images/icons/nvidia-inception-program.png"
                />
              </div>
        </div>
      </div>
      {/* Feature Section */}
      <div className="bg-white">
        <div className="py-8 lg:pt-16 px-8 lg:pt-24">
          <div className="font-productSans font-bold text-darkGreen text-[28px] sm:text-[28px] md:text-[36px] lg:text-[42px] xl:text-[44px] 2xl:text-[50px] lg:leading-[55px] xl:leading-[70px] 2xl:leading-[70px] text-center md:tracking-wide">
            With a <span className="text-blue">Clinical AI Assistant</span>{" "}
            built for unsurpassed efficiency and upside{" "}
          </div>
        </div>

        <div className="flex flex-col lg:flex-row mx-4 lg:mx-32 lg:gap-4 pb-4 md:pb-24">
          <div className="flex-1 flex flex-col gap-4 items-center shadow-md bg-gray p-8 m-4 transform transition duration-300 hover:scale-105 hover:shadow-lg hover:-translate-y-1 hover:perspective-500 hover:translate-z-10">
            <div className="hidden md:block">
              <Lottie
                animationData={feature1}
                loop={true}
                autoplay={true}
                className="h-48"
              />
            </div>
            <div className="font-productSans font-bold text-darkGreen text-[20px] sm:text-[20px] md:text-[20px] lg:text-[24px] xl:text-[24px] 2xl:text-[24px] text-center">
              Reduce administration burden on PT's
            </div>
            <div className="font-dmSans font-normal text-textColor text-[16px] sm:text-[16px] md:text-[16px] lg:text-[18px] xl:text-[18px] 2xl:text-[18px] text-center">
              Save physical therapists{" "}
              <span className="text-blue font-medium">
                {" "}
                2-3 hours of documentation
              </span>{" "}
              time on a daily basis
            </div>
          </div>

          <div className="flex-1 flex flex-col gap-4 items-center shadow-md bg-gray p-8 m-4 transform transition duration-300 hover:scale-105 hover:shadow-lg hover:-translate-y-1 hover:perspective-500 hover:translate-z-10">
            <div className="hidden md:block">
              <Lottie
                animationData={feature2}
                loop={true}
                autoplay={true}
                className="h-48"
              />
            </div>
            <div className="font-productSans font-bold text-darkGreen text-[20px] sm:text-[20px] md:text-[20px] lg:text-[24px] xl:text-[24px] 2xl:text-[24px] text-center">
              Improve quality of patient care{" "}
            </div>
            <div className="font-dmSans font-normal text-textColor text-[16px] sm:text-[16px] md:text-[16px] lg:text-[18px] xl:text-[18px] 2xl:text-[18px] text-center">
              Reduce PT burnout and fatigue while saving{" "}
              <span className="text-blue font-medium">
                $50-70k in attrition costs
              </span>
            </div>
          </div>

          <div className="flex-1 flex flex-col gap-4 items-center shadow-md bg-gray p-8 m-4 transform transition duration-300 hover:scale-105 hover:shadow-lg hover:-translate-y-1 hover:perspective-500 hover:translate-z-10">
            <div className="hidden md:block">
              <Lottie
                animationData={feature3}
                loop={true}
                autoplay={true}
                className="h-48"
              />
            </div>
            <div className="font-productSans font-bold text-darkGreen text-[20px] sm:text-[20px] md:text-[20px] lg:text-[24px] xl:text-[24px] 2xl:text-[24px] text-center">
              Increase note compliance with insurance{" "}
            </div>
            <div className="font-demoSans font-normal text-textColor text-[16px] sm:text-[16px] md:text-[16px] lg:text-[18px] xl:text-[18px] 2xl:text-[18px] text-center">
              Improve accuracy of notes by{" "}
              <span className="text-blue font-medium">
                reducing errors up to 50%
              </span>
            </div>
          </div>
        </div>
      </div>
      {/* Section Two */}
      <div
        id="section-2"
        ref={sectionTwoRef}
        className="bg-darkGreen md:h-screen lg:px-28 lg:py-3 flex flex-col md:flex-row"
      >
        <div
          id="animation-2"
          className="p-4 md:p-16 flex-1 my-auto flex items-center justify-center h-[450px]"
        >
          <Suspense
            fallback={
              <img src="./assets/images/fallback/Slide2_fallback.png" />
            }
          >
            {sectionTwoAnimation ? (
              <Section animationData={sectionTwoAnimation} />
            ) : (
              <img src="./assets/images/fallback/Slide2_fallback.png" />
            )}
          </Suspense>
        </div>
        <div className="flex-1 text-center px-4 pb-4 md:pb-0 md:text-left flex items-center md:items-center justify-center">
          <p className="text-beige font-productSans font-normal mx-4 lg:pl-9 text-[28px] sm:text-[28px] md:text-[36px] lg:text-[48px] lg:leading-[60px] xl:text-[52px] xl:leading-[64px] 2xl:text-[52px] 2xl:leading-[64px]">
            Harness <span className="text-gray">AI</span> to capture{" "}
            <span className="text-gray">clinical details</span> in conversation
            to build note automatically
          </p>
        </div>
      </div>
      {/* Section Three */}
      <div
        id="section-3"
        ref={sectionThreeRef}
        className="h-[650px] bg-darkGreen lg:px-28 sm:py-3 lg:py-0 flex flex-col-reverse md:flex-row"
      >
        <div className="px-4 py-8 md:py-0 md:flex-1 text-center md:text-left md:my-0 flex items-center justify-start">
          <p className="text-beige font-productSans font-normal text-[28px] sm:text-[28px] md:text-[36px] lg:text-[42px] lg:leading-[60px] xl:text-[46px] xl:leading-[64px] 2xl:text-[46px] 2xl:leading-[64px]">
            <span className="text-gray">Computer Vision</span> and AI that
            recognize and accurately capture a range of motion, posture, gait,
            and functional <span className="text-gray">movements</span>
          </p>
        </div>
        <div className="px-2 py-4 md:flex-1 my-auto flex items-center justify-end h-auto max-h-[450px]">
          <Suspense
            fallback={
              <img src="./assets/images/fallback/Slide3_fallback.png" />
            }
          >
            {sectionThreeAnimation ? (
              <Section animationData={sectionThreeAnimation} />
            ) : (
              <img src="./assets/images/fallback/Slide3_fallback.png" />
            )}
          </Suspense>
        </div>
      </div>
      {/* Section Four */}
      <div
        id="section-4"
        ref={sectionFourRef}
        className="sm:h-[650px] bg-darkGreen lg:px-28 py-3 flex flex-col md:flex-row items-center"
      >
        <div className="flex-1 my-auto flex items-center justify-center h-auto sm:min-h-[380px] lg:min-h-auto">
          <Suspense
            fallback={
              <img src="./assets/images/fallback/Slide4_fallback.png" />
            }
          >
            {sectionFourAnimation ? (
              <Section animationData={sectionFourAnimation} />
            ) : (
              <img src="./assets/images/fallback/Slide4_fallback.png" />
            )}
          </Suspense>
        </div>
        <div className="md:flex-1 px-4 py-8 md:py-0 text-center md:text-left md:my-0 flex items-center justify-center">
          <p className="text-beige font-productSans font-normal mx-4 lg:pl-9 text-[28px] sm:text-[28px] md:text-[36px] lg:text-[42px] lg:leading-[60px] xl:text-[46px] xl:leading-[64px] 2xl:text-[46px] 2xl:leading-[64px]">
            Informed AI models that can{" "}
            <span className="text-gray">
              suggest relevant billable diagnostic{" "}
            </span>
            codes and goals that support medical necessity
          </p>
        </div>
      </div>
      {/* Section Five */}
      <div
        id="section-5"
        ref={sectionFiveRef}
        className="sm:h-[640px] bg-darkGreen py-3 lg:px-28 flex flex-col-reverse md:flex-row items-center"
      >
        <div className="flex-1 px-4 py-8 md:py-0 text-center md:text-left flex items-center justify-start">
          <p className="text-beige font-productSans font-normal text-[28px] sm:text-[28px] md:text-[36px] lg:text-[42px] lg:leading-[60px] xl:text-[42px] xl:leading-[64px] 2xl:text-[42px] 2xl:leading-[64px]">
            <span className="text-gray">AI-generated</span> plan of care, goals,
            and home exercise programs are seamlessly delivered, allowing for{" "}
            <span className="text-gray">real-time editing and adaptation</span>{" "}
            as the patient progresses towards discharge
          </p>
        </div>
        <div className="flex-1 my-auto w-full flex items-center justify-center h-auto sm:max-h-[400px] lg:max-h-auto w-[350px] lg:w-auto">
          <Suspense
            fallback={
              <img src="./assets/images/fallback/Slide5_fallback.png" />
            }
          >
            {sectionFiveAnimation ? (
              <Section animationData={sectionFiveAnimation} />
            ) : (
              <img src="./assets/images/fallback/Slide5_fallback.png" />
            )}
          </Suspense>
        </div>
      </div>
      {/* Demo Section */}
      <div className="bg-white">
        <div className="py-8 pt-16">
          <div className="font-productSans font-bold text-darkGreen text-[32px] sm:text-[32px] md:text-[36px] lg:text-[42px] xl:text-[44px] 2xl:text-[50px] lg:leading-[55px] xl:leading-[70px] 2xl:leading-[70px] text-center lg:tracking-wide">
            Notation AI Clinical Documentation Platform
          </div>
        </div>

        <div className="flex flex-col gap-8 justify-center items-center">
          <Link
            to="/demo"
            className="lg:border-[18px] lg:border-black rounded-3xl"
          >
            <img
              className="lg:h-[600px] px-8 md:px-0 rounded-md"
              style={{
                cursor: `url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 64 64' width='64' height='64'><circle cx='32' cy='32' r='31' fill='%23439bcc' opacity='0.9'/><path d='M26 20 L42 32 L26 44 Z' fill='white'/></svg>") 32 32, auto`,
              }}
              src="/assets/images/landing/demo.png"
              alt="Demo"
            />
          </Link>
          <Link
            to="/demo"
            className="flex flex-row bg-blue text-white text-[16px] lg:text-[18px] font-productSans items-center lg:max-w-[250px] gap-4 px-4 lg:px-6 py-4 rounded-lg hover:bg-darkGreen tracking-wide"
          >
            Request Demo <FaArrowRight />
          </Link>
        </div>
      </div>
      {/* Testimonials Section */}
      <div className="bg-white pt-8 lg:pt-24">
        <div className="py-4 px-8">
          <div className="font-productSans font-bold text-darkGreen text-[32px] sm:text-[32px] md:text-[36px] lg:text-[42px] xl:text-[44px] 2xl:text-[50px] lg:leading-[55px] xl:leading-[70px] 2xl:leading-[70px] text-center lg:tracking-wide">
            See How Notation AI Transforms Care
          </div>
        </div>

        <div className="flex flex-col lg:flex-row mx-8 lg:mx-32 gap-4 pb-24">
          <div className="flex-1 flex flex-col gap-8 border-[2px] border-beige p-8 md:m-4 rounded-lg">
            <div className="font-dmSans font-medium text-textColor text-[16px] sm:text-[16px] md:text-[16px] lg:text-[18px] xl:text-[18px] 2xl:text-[18px] text-center">
              “With your vision, you have the opportunity here to standardize
              care across the industry, which would then allow us to have real
              data and efficacy measures.”​
            </div>
            <div className="font-dmSans font-normal text-darkGreen text-[16px] sm:text-[16px] md:text-[16px] lg:text-[18px] xl:text-[18px] 2xl:text-[18px] text-end">
              - Performance PT Clinic, CA, USA
            </div>
          </div>

          <div className="flex-1 flex flex-col gap-8 border-[2px] border-blue p-8 md:m-4 rounded-lg">
            <div className="font-dmSans font-medium text-textColor text-[16px] sm:text-[16px] md:text-[16px] lg:text-[18px] xl:text-[18px] 2xl:text-[18px] text-center">
              “That is beyond awesome. That is what PT needs to improve
              efficiency, improving billing and coding accuracy.”
            </div>
            <div className="font-dmSans font-normal text-darkGreen text-[16px] sm:text-[16px] md:text-[16px] lg:text-[18px] xl:text-[18px] 2xl:text-[18px] text-end">
              - Regional Manager PT Clinics, Indiana
            </div>
          </div>

          <div className="flex-1 flex flex-col gap-8 border-[2px] border-beige p-8 md:m-4 rounded-lg">
            <div className="font-dmSans font-medium text-textColor text-[16px] sm:text-[16px] md:text-[16px] lg:text-[18px] xl:text-[18px] 2xl:text-[18px] text-center">
              “That is the best AI EMR I have ever seen. Through all my research
              there has not been that level of focus and purpose behind the
              design of a EMR and SOAP note.”
            </div>
            <div className="font-dmSans font-normal text-darkGreen text-[16px] sm:text-[16px] md:text-[16px] lg:text-[18px] xl:text-[18px] 2xl:text-[18px] text-end">
              - IT Executive, Alliance Physical Therapy Partners
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Intro;
