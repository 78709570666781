import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { Layout } from "../component/Common";
import { Loading } from "../component/ui";
import Auth from "../pages/Auth";
import BusinessModel from "../pages/BusinessModel";
import PageNotFound from "../pages/PageNotFound";
import MultiStepForm from "../pages/MultiStepForm";
import Graph from "../pages/Graph";
import { TeamDetail } from "../pages/TeamDetail";
import NONPTSuvery from "../pages/NONPTSuvery";
import ContactUS from "../pages/ContactUS";
import Scale from "../pages/Scale";
import { ItemsContext } from "../data/context";
import DemoSuccess from "../pages/DemoSuccess";
import Disclaimer from "../pages/Disclaimer";
import FounderNotes from "../pages/FounderNotes";
import SharePage from "../pages/Share/SharePage";
import SurveyStart from "../pages/Share/SurveyStart";
import Contact from "../pages/Contact";
import Demo from "../pages/Demo";
import TeamMembers from "../pages/AboutUs";

const AppRoutes = () => {
  const isMobile = () => {
    if (window.innerWidth >= 768) {
      return true;
    } else {
      return false;
    }
  };

  const { setIsPolicyModelOpen } = useContext(ItemsContext);

  const policyCheck = localStorage.getItem("fownd-policy");
  console.log(policyCheck, "policyCheck");
  if (policyCheck == null) {
    setIsPolicyModelOpen(true);
  }

  return (
    <>
      <Routes>
        <Route
          path="*"
          element={
            <React.Suspense fallback={<Loading />}>
              <PageNotFound />
            </React.Suspense>
          }
        />
        <Route element={<Layout />}>
          <Route
            path="/auth"
            element={
              <React.Suspense fallback={<Loading />}>
                <Auth />
              </React.Suspense>
            }
          />
        </Route>

        <Route element={<Layout darkMenu={true} />}>
          <Route
            path="/"
            element={
              <React.Suspense fallback={<Loading />}>
                {/* <LandingPage /> */}
                <SharePage />
              </React.Suspense>
            }
          />
        </Route>

        <Route element={<Layout />}>
          <Route
            path="/business-model"
            element={
              <React.Suspense fallback={<Loading />}>
                <BusinessModel />
              </React.Suspense>
            }
          />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/demo"
            element={
              <React.Suspense fallback={<Loading />}>
                <Demo />
              </React.Suspense>
            }
          />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/disclaimer"
            element={
              <React.Suspense fallback={<Loading />}>
                <Disclaimer />
              </React.Suspense>
            }
          />
        </Route>
        <Route element={<Layout />}>
          <Route
            path="/survey-confirm"
            element={
              <React.Suspense fallback={<Loading />}>
                <DemoSuccess />
              </React.Suspense>
            }
          />
        </Route>
        <Route
          path="/survey"
          element={
            <React.Suspense fallback={<Loading />}>
              <MultiStepForm />
            </React.Suspense>
          }
        />
        <Route element={<Layout />}>
          <Route
            path="/non-pt-survey"
            element={
              <React.Suspense fallback={<Loading />}>
                <NONPTSuvery />
              </React.Suspense>
            }
          />
        </Route>
        <Route element={<Layout darklightMenu={true} />}>
          <Route
            path="/success"
            element={
              <React.Suspense fallback={<Loading />}>
                <Graph />
              </React.Suspense>
            }
          />
        </Route>
        <Route element={<Layout darkMenu={isMobile()} />}>
          <Route
            path="/about"
            element={
              <React.Suspense fallback={<Loading />}>
                <TeamMembers />
              </React.Suspense>
            }
          />
        </Route>
        <Route element={<Layout darkMenu={true} />}>
          <Route
            path="/invest"
            element={
              <React.Suspense fallback={<Loading />}>
                <Contact />
              </React.Suspense>
            }
          />
        </Route>
        <Route element={<Layout darkMenu={false} />}>
          <Route
            path="/contact-us"
            element={
              <React.Suspense fallback={<Loading />}>
                <ContactUS />
              </React.Suspense>
            }
          />
        </Route>
        <Route element={<Layout darkMenu={false} />}>
          <Route
            path="/help-scale"
            element={
              <React.Suspense fallback={<Loading />}>
                <Scale />
              </React.Suspense>
            }
          />
        </Route>

        <Route element={<Layout darkMenu={true} />}>
          <Route
            path="/founder-notes"
            element={
              <React.Suspense fallback={<Loading />}>
                <FounderNotes />
              </React.Suspense>
            }
          />
        </Route>
        <Route element={<Layout darkMenu={true} />}>
          <Route
            path="/team/:name"
            element={
              <React.Suspense fallback={<Loading />}>
                <TeamDetail />
              </React.Suspense>
            }
          />
        </Route>

        <Route
          path="/start-survey"
          element={
            <React.Suspense fallback={<Loading />}>
              <SurveyStart />
            </React.Suspense>
          }
        />
      </Routes>
    </>
  );
};

export default AppRoutes;
