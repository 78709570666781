import React from "react";

import clsx from "clsx";

import LoadingCircle from "../LoadingCircle";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
  text?: string;
  onClick?: any;
  width?: string | number;
  loading?: boolean;
  outlined?: boolean;
  disabled?: boolean;
}

const Button: React.FC<ButtonProps> = (props) => {
  const {
    className,
    outlined = false,
    width = 44,
    text = "base",
    onClick,
    loading,
    disabled,
    children,
    ...rest
  } = props;
  return (
    <button
      className={clsx(
        className,
        outlined
          ? `text-white bg-blue shadow-xl shadow-gray/10`
          : `bg-black text-white shadow-xl shadow-black/10`,
        `inline-flex h-10 select-none items-center justify-center rounded w-${width} text-${text} px-7 font-medium leading-5 outline-none shadow-lg rounded-full`,
        loading ? "cursor-not-allowed" : "cursor-pointer",
      )}
      onClick={onClick}
      disabled={loading ? true : false}
      {...rest}
    >
      {loading && <LoadingCircle />}
      {children}
    </button>
  );
};

export default Button;
