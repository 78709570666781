import { useContext } from "react";

// import clsx from "clsx";
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

import { Sidebar } from "../../ui/Modal";
import { ItemsContext } from "../../../data/context";
import { HamburgIcon, HamburgIconLight, SiteLogo } from "../../Icons";
import { FaLaptopMedical } from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";

const Navbar = ({ ...props }) => {
  let navigate = useNavigate();

  const { isSideBarOpen, setIsSideBarOpen } = useContext(ItemsContext);

  const openModal = () => {
    setIsSideBarOpen(true);
  };

  const closeModal = () => {
    setIsSideBarOpen(false);
  };

  return (
    <div className="relative sticky top-0 z-50">
      <div className="absolute bg-gray px-4 md:px-14 w-full py-1 z-10 flex justify-between">
        <div>
          <SiteLogo
            onClick={() => navigate(`/`)}
            className="text-darkGreen cursor-pointer w-8 sm:w-12 h-full"
          />
        </div>

        {/* Desktop Navigation */}
        <div className="hidden md:flex font-inter justify-center items-center space-x-12">
          <HashLink
            to="/#section-1"
            className="text-darkGreen md:text-[14px] lg:text-[18px] xl:text-[18px] 2xl:text-[20px] font-medium"
          >
            Home
          </HashLink>
          <Link
            to="/about"
            className="text-darkGreen md:text-[14px] lg:text-[18px] xl:text-[18px] 2xl:text-[20px] font-medium"
          >
            About
          </Link>
          <div className="relative flex group h-full items-center">
            <Link
              to="#"
              className="text-darkGreen md:text-[14px] lg:text-[18px] xl:text-[18px] 2xl:text-[20px] font-medium"
            >
              Products
            </Link>

            {/* Submenu */}
            <div className="absolute hidden group-hover:flex flex-row items-center gap-2 bg-gray shadow-md py-4 px-4 mt-32 w-[200px]">
              <FaLaptopMedical size={20} color="#1F3D3B" />
              <Link
                to="/demo"
                className="text-darkGreen md:text-[14px] lg:text-[18px] xl:text-[18px] 2xl:text-[20px] font-normal"
              >
                Notation AI
              </Link>
            </div>
          </div>

          <Link
            to="/founder-notes"
            className="text-darkGreen md:text-[14px] lg:text-[18px] xl:text-[18px] 2xl:text-[20px] font-medium"
          >
            Founder's Note
          </Link>

          <Link
            to="/invest"
            className="text-darkGreen md:text-[14px] lg:text-[18px] xl:text-[18px] 2xl:text-[20px] font-medium"
          >
            Contact
          </Link>
        </div>

        <div className="md:hidden flex justify-center items-center">
          <Link
            to="/demo"
            className="md:hidden px-2 py-2 mx-4 text-[12px] rounded-sm font-inter text-gray bg-darkGreen tracking-wide"
          >
            Notation AI
          </Link>

          <GiHamburgerMenu
            color="#1F3D3B"
            size={22}
            className="cursor-pointer w-6 sm:w-[38px] h-6 sm:h-[38px]"
            onClick={openModal}
          />
        </div>
      </div>

      <Sidebar isOpen={isSideBarOpen} closeModal={closeModal} />
    </div>
  );
};

export default Navbar;
